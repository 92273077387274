<script lang="ts" setup>
import { Vue3SlideUpDown } from 'vue3-slide-up-down';

const modelValue = defineModel<boolean>({ default: false });
defineProps<{ duration?: number }>();
</script>

<template>
  <Vue3SlideUpDown
    v-model="modelValue"
    :duration="duration ?? 200"
  >
    <slot />
  </Vue3SlideUpDown>
</template>
